<template>
  <div class="container-fluid" v-if="!busy">
    <div class="row">
      <!-- SIDEBAR -->
      <div class="col-3">
        <div
          class="sticky-top bg-light"
          style="height: 84vh; overflow-y: scroll; overflow-x: hidden"
        >
          <div class="row">
            <div class="col">
              <div class="container bg-light mb-3 pb-2">
                <div class="row mb-3">
                  <div class="col">
                    <h5 class="mb-3 mt-3">Form Settings</h5>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Form Name..."
                      v-model="form.title"
                      @change="updateName"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <label>Category</label>
                    <select
                      v-model="form.category"
                      class="form-control"
                      @change="updateForm"
                    >
                      <option
                        v-for="c in categories"
                        :value="c"
                        :selected="form.category === c"
                      >
                        {{ c }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <label>Status</label>
                    <select
                      v-model="form.status"
                      class="form-control"
                      @change="updateForm"
                    >
                      <option value="draft" :selected="form.status == 'draft'">
                        Draft
                      </option>
                      <option
                        value="published"
                        :selected="form.status == 'published'"
                      >
                        Published
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="shareWithClinic"
                        v-model="form.is_public"
                        :checked="form.is_public"
                        @change="updateForm"
                      />
                      <label class="custom-control-label" for="shareWithClinic"
                        >Public Form</label
                      >
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row" v-if="form.id">
                  <div class="col">
                    <div class="row my-2">
                      <div class="col">
                        <h5 class="d-inline-block mt-1">Form Sections</h5>
                        <button
                          class="float-right btn btn-outline-success btn-sm"
                          @click="showAddSection = true"
                          v-if="!showAddSection"
                        >
                          <i class="fad fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="showAddSection">
                      <div class="col">
                        <div class="row mb-3">
                          <div class="col">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Section Name..."
                              v-model="newSectionTitle"
                              @keyup.enter="addNewSection"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-right">
                            <button
                              class="btn-outline-danger btn me-2"
                              @click="cancelAddSection"
                            >
                              <i class="fad fa-times-circle me-1"></i>
                              Cancel
                            </button>
                            <button
                              class="btn-outline-success btn"
                              @click="addNewSection"
                            >
                              <i
                                class="fad fa-save me-1"
                                v-if="!loadingNewSection"
                              ></i>
                              <i class="fad fa-spinner fa-spin me-1" v-else></i>
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-else-if="form.sections.length > 0">
                      <div class="col">
                        <draggable
                          class="list-group"
                          tag="ul"
                          handle=".handle"
                          v-model="form.sections"
                          v-bind="dragOptions"
                          @change="updateSectionsOrder"
                        >
                          <li
                            class="list-group-item"
                            v-for="section in form.sections"
                            :key="section.order"
                            :class="
                              selectedSection &&
                              selectedSection.id === section.id
                                ? 'bg-success text-white'
                                : ''
                            "
                          >
                            <i
                              class="fa fa-align-justify handle me-3"
                              style="cursor: move"
                            ></i>
                            <span v-if="editSection !== section.id">
                              <span
                                class="cursor-pointer"
                                @click="selectSection(section)"
                              >
                                {{ section.order }}. {{ section.title }}
                                <span
                                  class="badge badge-pill badge-success ms-2"
                                  v-if="section.scored"
                                  >Scored</span
                                >
                              </span>
                              <span class="float-right">
                                <i
                                  class="cursor-pointer fad fa-pencil me-2"
                                  v-tooltip.bottom="'Edit Section'"
                                  @click.stop="editSection = section.id"
                                ></i>
                                <i
                                  class="cursor-pointer fad fa-copy me-2"
                                  v-tooltip.bottom="'Duplicate Section'"
                                  @click.stop="duplicateSection(section)"
                                ></i>
                                <i
                                  class="cursor-pointer fad fa-trash"
                                  v-tooltip.bottom="'Remove Section'"
                                  @click.stop="deleteSection(section)"
                                ></i>
                              </span>
                            </span>
                            <span v-else>
                              <input
                                type="text"
                                @click.stop
                                v-model="section.title"
                                @change="renameSection(section)"
                                @keyup.enter="renameSection"
                                class="form-control w-75 d-inline"
                              />

                              <div class="form-check form-check-inline ms-4">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  @change="renameSection(section)"
                                  v-model="section.scored"
                                  :id="'scoredSection' + section.id"
                                />
                                <label
                                  class="form-check-label"
                                  v-tooltip.bottom="
                                    'If the section is marked as scored ALL number inputs in that section will be added together to provide a section score'
                                  "
                                  :for="'scoredSection' + section.id"
                                >
                                  Scored Section
                                  <i class="far fa-info-circle ms-2"></i>
                                </label>
                              </div>
                            </span>
                          </li>
                        </draggable>
                      </div>
                    </div>
                    <div
                      class="row mb-3"
                      v-if="
                        !showAddSection &&
                        (!form.sections || form.sections.length === 0)
                      "
                    >
                      <div class="col text-center">
                        <p>No sections have been created yet.</p>
                        <button
                          class="btn btn-outline-success"
                          @click="showAddSection = true"
                        >
                          <i class="fad fa-plus me-1"></i>
                          Add Form Section
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MAIN VIEW -->
      <div class="col order-2">
        <div>
          <div class="container-fluid shadow-sm bg-light pb-2">
            <div class="row mb-3">
              <div class="col">
                <h5 class="mt-3">
                  Swandoola Form Builder
                  <span v-if="form.title"
                    ><small>({{ form.title }})</small></span
                  >
                  <button
                    class="btn btn-outline-success btn-sm float-right"
                    :class="
                      previewView
                        ? 'btn-success text-white'
                        : 'btn-outline-success'
                    "
                    v-if="sectionsHaveAtLeastOneQ"
                    @click="showPreview"
                  >
                    <i class="fad fa-eye me-1"></i>
                    Preview
                  </button>
                  <button
                    class="btn btn-sm btn-outline-success me-2 float-right"
                    :class="
                      !tutorialView && !previewView
                        ? 'btn-success text-white'
                        : 'btn-outline-success'
                    "
                    @click="showBuilder"
                  >
                    <i class="fad fa-user-hard-hat me-1"></i>
                    Builder
                  </button>
                  <button
                    class="btn btn-sm float-right me-2"
                    :class="
                      tutorialView && !previewView
                        ? 'btn-success text-white'
                        : 'btn-outline-success'
                    "
                    @click="showTutorial"
                  >
                    <i class="fad fa-graduation-cap me-1"></i>
                    Tutorial
                  </button>
                </h5>
              </div>
            </div>

            <!-- TUTORIAL VIEW -->
            <div :class="!tutorialView || previewView ? 'd-none' : ''">
              <div class="row mb-3">
                <div class="col-6 mx-auto">
                  <div class="card bg-white">
                    <div class="card-body text-center">
                      <span
                        v-if="this.form.title"
                        class="position-absolute"
                        style="top: 10px; right: 10px"
                      >
                        <i class="fad fa-2x fa-check-circle text-success"></i>
                      </span>
                      <h4 class="text-success">Step 1</h4>
                      Give your form a <b>name</b> and setup publishing
                      settings.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6 mx-auto">
                  <div class="card bg-white">
                    <div class="card-body text-center">
                      <span
                        v-if="this.form.sections.length > 0"
                        class="position-absolute"
                        style="top: 10px; right: 10px"
                      >
                        <i class="fad fa-2x fa-check-circle text-success"></i>
                      </span>
                      <h4 class="text-success">Step 2</h4>
                      Add one or more
                      <b class="cursor-pointer" @click="showBuilder"
                        >sections</b
                      >
                      to your form. You can have as many as you like, but each
                      form must have at least one section.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6 mx-auto">
                  <div class="card bg-white">
                    <div class="card-body text-center">
                      <span
                        v-if="sectionsHaveAtLeastOneQ"
                        class="position-absolute"
                        style="top: 10px; right: 10px"
                      >
                        <i class="fad fa-2x fa-check-circle text-success"></i>
                      </span>
                      <h4 class="text-success">Step 3</h4>
                      Add <b>form elements</b> to your custom form sections. You
                      can have as many as you like, but each form section should
                      have at least one question.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6 mx-auto">
                  <div class="card bg-white">
                    <div class="card-body text-center">
                      <span
                        v-if="form.status === 'published'"
                        class="position-absolute"
                        style="top: 10px; right: 10px"
                      >
                        <i class="fad fa-2x fa-check-circle text-success"></i>
                      </span>
                      <h4 class="text-success">Step 4</h4>
                      <b>Publish</b> your form and start sending it to clients!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6 mx-auto">
                  <div class="card bg-white">
                    <div class="card-body text-center">
                      <h4 class="text-success">Tip</h4>
                      You can <b>preview</b> how your form will look at any
                      time.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- BUILDER VIEW -->
            <div :class="tutorialView || previewView ? 'd-none' : ''">
              <div v-if="sectionLoading && selectedSection">
                <div class="row pt-5 pb-3">
                  <div class="col text-center">
                    <h4 class="mb-5">
                      <i class="fad fa-spinner fa-spin fa-4x text-success"></i>
                    </h4>
                    <h5>
                      Loading form section: {{ selectedSection.title }}...
                    </h5>
                  </div>
                </div>
              </div>
              <div :class="sectionLoading ? 'd-none' : ''">
                <div :class="!selectedSection ? 'd-none' : ''">
                  <div class="row mb-3" v-if="selectedSection">
                    <div class="col-6">
                      <i
                        class="fad fa-2x fa-info-circle cursor-pointer text-success"
                        v-tooltip.bottom="
                          'Drag and drop elements from the right hand side to the left, arrange them, customise them and save your section. Remember to save changes to each section before moving to the next.'
                        "
                      ></i>
                    </div>
                    <div class="col-6 text-right">
                      <button
                        class="btn btn-outline-success btn-sm me-2"
                        @click="selectedSection = null"
                      >
                        <i class="fad fa-arrow-left me-1"></i>
                        Back to Sections Overview
                      </button>
                      <button
                        class="btn btn-outline-success btn-sm"
                        @click="updateSection"
                      >
                        <i class="fa fa-save me-1"></i>
                        Save Section
                      </button>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div id="fb-editor"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!form.sections.length > 0">
                <div class="row mb-3">
                  <div class="col text-center text-success">
                    <h4>
                      <i class="fad fa-4x fa-info-circle"></i>
                    </h4>
                    <h5 class="my-4">
                      No form sections have been created yet.
                    </h5>
                    <div class="row mb-3">
                      <div class="col-6 mx-auto">
                        <div class="row">
                          <div class="col">
                            <input
                              type="text"
                              class="form-control mb-3"
                              placeholder="Section Name..."
                              v-model="newSectionTitle"
                              @keyup.enter="addNewSection"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            <button
                              class="btn-outline-success btn"
                              @click="addNewSection"
                            >
                              <i
                                class="fad fa-plus me-1"
                                v-if="!loadingNewSection"
                              ></i>
                              <i class="fad fa-spinner fa-spin me-1" v-else></i>
                              Add Form Section
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="!selectedSection">
                <div class="row">
                  <div class="col">
                    <p class="alert alert-success">
                      <i class="fad fa-info-circle me-2"></i>
                      Drag and drop sections to re-arrange the order in which
                      they are presented. Click into a section to add form
                      elements.
                    </p>
                  </div>
                </div>
                <div class="row mb-3" v-if="form.sections.length > 0">
                  <div class="col-6 mx-auto">
                    <h5>Form Sections</h5>
                    <draggable
                      class="list-group"
                      tag="ul"
                      handle=".handle"
                      v-model="form.sections"
                      v-bind="dragOptions"
                      @change="updateSectionsOrder"
                    >
                      <li
                        class="list-group-item"
                        v-for="section in form.sections"
                        :key="section.order"
                        :class="
                          selectedSection && selectedSection.id === section.id
                            ? 'bg-success text-white'
                            : ''
                        "
                      >
                        <i
                          class="fa fa-align-justify handle me-3"
                          style="cursor: move"
                        ></i>
                        <span v-if="editSection !== section.id">
                          <span
                            class="cursor-pointer"
                            @click="selectSection(section)"
                          >
                            {{ section.order }}. {{ section.title }}
                            <span
                              class="badge badge-pill badge-success ms-2"
                              v-if="section.scored"
                              >Scored</span
                            >
                          </span>
                          <span class="float-right">
                            <i
                              class="cursor-pointer fad fa-pencil me-2"
                              v-tooltip.bottom="'Edit Section'"
                              @click.stop="editSection = section.id"
                            ></i>
                            <i
                              class="cursor-pointer fad fa-copy me-2"
                              v-tooltip.bottom="'Duplicate Section'"
                              @click.stop="duplicateSection(section)"
                            ></i>
                            <i
                              class="cursor-pointer fad fa-trash"
                              v-tooltip.bottom="'Remove Section'"
                              @click.stop="deleteSection(section)"
                            ></i>
                          </span>
                        </span>
                        <span v-else>
                          <input
                            type="text"
                            @click.stop
                            v-model="section.title"
                            @change="renameSection(section)"
                            @keyup.enter="renameSection"
                            class="form-control w-auto d-inline"
                          />
                          <div class="form-check form-check-inline ms-4">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              @change="renameSection(section)"
                              v-model="section.scored"
                              :id="'scoredSection' + section.id"
                            />
                            <label
                              class="form-check-label"
                              v-tooltip.bottom="
                                'If the section is marked as scored ALL number inputs in that section will be added together to provide a section score'
                              "
                              :for="'scoredSection' + section.id"
                            >
                              Scored Section
                              <i class="far fa-info-circle ms-2"></i>
                            </label>
                          </div>
                        </span>
                      </li>
                    </draggable>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 mx-auto">
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Section Name..."
                      v-model="newSectionTitle"
                      @keyup.enter="addNewSection"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col text-center">
                    <button
                      class="btn-outline-success btn"
                      @click="addNewSection"
                    >
                      <i class="fad fa-plus me-1" v-if="!loadingNewSection"></i>
                      <i class="fad fa-spinner fa-spin me-1" v-else></i>
                      Add Form Section
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- PREVIEW VIEW -->
            <div :class="tutorialView || !previewView ? 'd-none' : ''">
              <div class="row" v-if="form">
                <div class="col-8 mx-auto">
                  <div class="card">
                    <div class="card-body">
                      <div class="container">
                        <div v-if="form">
                          <div class="row">
                            <div class="col my-auto">
                              <h5>{{ form.title }}</h5>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col">
                              <p class="alert alert-success mb-3">
                                Please complete the following form sections.
                                Your changes are saved automatically, so you can
                                pause and come back at any time.
                              </p>
                            </div>
                          </div>

                          <div class="row mb-4">
                            <div class="col">
                              <div class="accordion" id="sectionsAccordion">
                                <div
                                  v-for="section in form.sections"
                                  :key="section.id"
                                  class="shadow"
                                >
                                  <div
                                    class="card sticky-top fixed-top shadow-none"
                                  >
                                    <div
                                      class="card-header pl-1 pr-3"
                                      :id="'heading' + section.id"
                                    >
                                      <h2 class="mb-0">
                                        <button
                                          class="btn btn-link fw-bold w-75 text-left"
                                          type="button"
                                          data-toggle="collapse"
                                          :data-target="
                                            '#collapse' + section.id
                                          "
                                          aria-expanded="false"
                                          :aria-controls="
                                            'collapse' + section.id
                                          "
                                        >
                                          <i class="fad fa-caret-down me-2"></i>
                                          {{ section.title }}
                                        </button>
                                        <span class="small float-right">
                                          <small
                                            >({{
                                              getNumberOfQs(section)
                                            }})</small
                                          >
                                        </span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div class="card">
                                    <div
                                      :id="'collapse' + section.id"
                                      class="collapse"
                                      :aria-labelledby="'heading' + section.id"
                                      data-parent="#sectionsAccordion"
                                    >
                                      <div
                                        class="card-body"
                                        :ref="'body-' + section.id"
                                      >
                                        <form
                                          :id="'form-render-' + section.id"
                                          class="previewPrint"
                                        ></form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col">
                              <button
                                class="btn btn-secondary"
                                @click="PrintFull()"
                              >
                                <i class="fas fa-print me-1"></i>
                                Print Preview
                              </button>
                            </div>
                            <div class="col text-right">
                              <button class="btn btn-success">
                                <i class="fad fa-spinner fa-spin me-1"></i>
                                Send Response
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Form Builder
require("jquery-ui-bundle");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");
import draggable from "vuedraggable";
export default {
  data() {
    return {
      busy: true,
      loadingNewSection: false,
      tutorialView: true,
      previewView: false,
      created: false,
      formBuilder: null,
      selectedSection: null,
      sectionLoading: false,
      showAddSection: false,
      newSectionTitle: "",
      form: {
        id: null,
        title: "",
        status: "draft",
        is_public: false,
        sections: [],
        category: "",
      },
      editSection: null,
      categories: [
        "Adult Health Intake Questionnaire",
        "Child Health Intake Questionnaire",
        "Food Diary",
        "Other",
      ],
    };
  },
  methods: {
    fetchForm() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/form-templates/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.form = data;
          if (this.form.sections.length > 0) {
            this.tutorialView = false;
          }
          this.busy = false;
        });
    },
    createForm() {
      this.created = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/form-templates", this.form)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.form = data.form;
        });
    },
    updateForm() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/form-templates/" + this.form.id,
          this.form
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.form = data.form;
        });
    },
    renameSection(section) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/form-templates/section/" + section.id,
          {
            section: section,
          }
        )
        .then(({ data }) => {
          this.form = data.form;
          this.editSection = null;
          this.$EventBus.$emit("alert", data);
        });
    },
    updateSection(sectionId = null) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/form-templates/section/" +
            this.selectedSection.id,
          {
            section: {
              title: this.selectedSection.title,
            },
            form_options: this.formBuilder.actions.getData(),
          }
        )
        .then(({ data }) => {
          this.form = data.form;
          this.$EventBus.$emit("alert", data);
        });
    },
    updateName() {
      if (!this.created && !this.form.id && this.form.title.length > 2) {
        this.createForm();
      } else {
        this.updateForm();
      }
    },
    addNewSection() {
      this.loadingNewSection = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/form-templates/section", {
          form_template_id: this.form.id,
          title: this.newSectionTitle,
        })
        .then(({ data }) => {
          this.form = data.form;
          this.cancelAddSection();
          this.$EventBus.$emit("alert", data);
          this.loadingNewSection = false;
        });
    },
    cancelAddSection() {
      this.showAddSection = false;
      this.newSectionTitle = "";
    },
    sort() {
      this.form.sections = this.form.sections.sort((a, b) => a.order - b.order);
    },
    updateSectionsOrder(event) {
      if (event.moved) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/form-templates/" +
              event.moved.element.form_template_id +
              "/order-sections",
            this.form.sections
          )
          .then(({ data }) => {
            this.form = data.form;
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    deleteSection(section) {
      var confirmed = confirm(
        "Are you sure you wish to delete this section? All contained questions will also be removed. This action cannot be undone."
      );
      if (confirmed) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL +
              "/form-templates/section/" +
              section.id
          )
          .then(({ data }) => {
            this.form = data.form;
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    duplicateSection(section) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/form-templates/section/" +
            section.id +
            "/duplicate"
        )
        .then(({ data }) => {
          this.form = data.form;
          this.$EventBus.$emit("alert", data);
        });
    },
    selectSection(section) {
      this.changeSection(section);
    },
    changeSection(section) {
      this.tutorialView = false;
      this.sectionLoading = true;
      this.selectedSection = section;

      var onFieldAdded = function (event, section) {
        console.log("FieldAdded Section " + section.id);
      };

      //clear builder to reinstantiate next
      $("#fb-editor").html("");

      this.formBuilder = $(document.getElementById("fb-editor")).formBuilder({
        disabledActionButtons: ["data", "clear"],
        disabledAttrs: ["access", "required", "class", "name"],
        disableFields: ["hidden", "button", "autocomplete", "file"],
      });

      setTimeout(() => {
        this.formBuilder.actions.setData(section.form_options);
        this.sectionLoading = false;
      }, 500);
    },
    showPreview() {
      this.tutorialView = false;
      this.previewView = true;

      for (let i = 0; i < this.form.sections.length; i++) {
        const section = this.form.sections[i];

        setTimeout(() => {
          var formRenderOptions = {
            dataType: "json",
            formData: section.form_options,
          };

          $("#form-render-" + section.id).formRender(formRenderOptions);
        }, 500);
      }
    },
    showBuilder() {
      this.tutorialView = false;
      this.previewView = false;
    },
    showTutorial() {
      this.tutorialView = true;
      this.previewView = false;
    },
    getNumberOfQs(section) {
      var count = 0;

      const options = JSON.parse(section.form_options);
      if (options) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].type !== "header" && options[i].type !== "paragraph") {
            count++;
          }
        }
      }
      return count;
    },
    replacer(key, value) {
      if (typeof value === "boolean") {
        //this is probably the worst code i'll ever write
        //but the library stores it this way so what can ya do
        if (value === true) {
          return "0";
        } else {
          return "1";
        }
      }
      return value;
    },
    testFunc() {
      console.log("ok");
    },
    PrintFull() {
      let innerHTML = "";
      let headerHTML = "<div class='container'>";
      let footerHTML = "</div>";
      let section_headerHTML =
        "<div class='row'><div class='col'><div class='card'>";
      let section_footerHTML = "</div></div></div>";

      innerHTML += headerHTML;
      for (const node of [...document.querySelectorAll(".previewPrint")]) {
        innerHTML += section_headerHTML;
        innerHTML += node.outerHTML;
        innerHTML += section_footerHTML;
      }
      innerHTML += footerHTML;

      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      const win = window.open(
        "",
        "_blank",
        "toolbar=0,scrollbars=0,status=0",
        true
      );
      win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Document Preview</title>
          </head>
          <body>
            ${innerHTML}
          </body>
        </html>
      `);
      setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        win.close();
        cb();
      }, 1000);
    },
  },
  computed: {
    sectionsHaveAtLeastOneQ() {
      //check all sections for at least one q here.
      //for tutorial purpose
      var ret = false;
      if (this.form.sections) {
        for (let i = 0; i < this.form.sections.length; i++) {
          if (this.form.sections[i].form_options) {
            return true;
          }
        }
      }
      return false;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {},
  mounted() {
    if (this.$route.params.id) {
      this.form = this.fetchForm();
      this.created = true;
    } else {
      this.busy = false;
    }

    document.addEventListener("formSaved", this.updateSection);
  },
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item i {
  cursor: pointer;
}

.form-builder .stage-wrap {
  min-height: 250px !important;
}

.form-builder .frmb-control {
  margin-left: 13px !important;
}
</style>